






























import { Vue, Component, Watch } from "vue-property-decorator";
import { api_banner, banner } from "@/http/banner";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
import Upimg from "@/components/upimg/index.vue";
import { api_user, user } from "@/http/user";
import {
  provinceAndCityData,
  pcTextArr,
  regionData,
  pcaTextArr,
  codeToText,
} from "element-china-area-data";
@Component({
  components: {
    PageHeader,
    Upimg,
  },
})
export default class extends Vue {
  info: banner = {
    id: 0,
    url:"",
    type: 1,
  };
  coverUrls: string[] = [];
  pcaTextArr = pcaTextArr;
  selectedOptions: string[] = [];
  baseRules = {
    url: [{ required: true, message: "请上传图片" }],
  };
  generateRules(isEdit: boolean) {
    const rulesCopy = { ...this.baseRules }; // 创建 baseRules 的浅拷贝
    console.log(rulesCopy);
    if (isEdit) {
      // 在编辑模式下，清除 password 字段的验证规则
      (rulesCopy as { password?: any }).password = undefined;
      delete (rulesCopy as { password?: any }).password;
    }

    return rulesCopy;
  }
  
  async submit() {
    console.log(this.info)
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    if (this.id) {
      await api_banner.edit(this.info);
    } else {
      await api_banner.create(this.info);
    }
    this.$router.go(-1);
  }

  async get_info() {
    this.info = await api_banner.get_info(this.id as number);
    // 处理封面图
    if (typeof this.info.url === "string") {
      const domain = "https://images.xiyuebiaoju.com/";
      this.coverUrls = this.info.url
        .split(",")
        .map((url) => domain + url.trim());
    }
  }
  // 监听图片数据
  @Watch("coverUrls")
  setCover(val: string) {
    const domain = "https://images.xiyuebiaoju.com/";
    this.info.url = val.replace(domain, "")
  }
  get id(): undefined | number {
    return this.$route.query.id as any;
  }

  created() {
    if (this.id === undefined) return;
    this.get_info();
  }
}
